import React, { useState, useEffect } from "react";
// import { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import Footer from "./Footer";

export default function Console(){
    const [apiCall1, setApiCall1] = useState(null);
    const [urlData, setUrlData] = useState(null);
    
    useEffect(() => {
        getUrl();
    },[]);
    
    useEffect(() => {
        if(apiCall1 !== null){
            if(apiCall1){
                console.log(urlData);
                showLoading(false);
            }else{
                logOut();
            }
        }
    },[apiCall1]);
    
    function getCookie(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
          if (match) {
            return match[2];
          }
          else{
               return null;
          }
        }
    function getUrl(){
        var token = getCookie("token");
        if(token !== null){
            let config={headers:{'Accept' : '*/*', 'Authorization' : token}};
            let url = 'https://api.cephlon.tech/common/url-scope';
            let body = '';
            axios.post(url, body, config).then((response) => {
                if(response.data.Status === 'Successful'){
                    setUrlData(response.data["Url-Data"]);
                    setApiCall1(true);
                }else{
                    logOut()
                }

            });
        }else{
            logOut();
        }
    }

    function logOut(){
        window.location.href = "https://cephlon.tech";
    }

    function showLoading(command){
        if(document.getElementById("loaders") !== null){
        if(command){
            document.getElementById("loaders").classList.remove("hidden");
        }else{
            document.getElementById("loaders").classList.add("hidden");
    }}}
    function startRedirect(data){
        window.open(data.target.id, '_blank').focus();
    }
    return(
        <div>
            <NavBar></NavBar>
        <div className="w-full h-100 lg:h-40">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 m-20 " id="mainView">
        {urlData !== null ? urlData.map((data, index) => {
            return(
                <a id={data.URL} key={index} className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded text-center" onClick={startRedirect}>{data.Service}</a>
            )
        }) : showLoading(true)}

        </div>
        <div className="float " id="loaders">
        <div className="flex intems-center justify-center mt-10">
            <div className="m-1 spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0 text-blue-500" role="status" />
            <div className="m-1 spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0 text-blue-500" role="status" />
            <div className="m-1 spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0 text-blue-500" role="status" />
        </div>
        </div>
        </div>
        <Footer></Footer>
        </div>
    );
}