import React from "react";

import AllRoutes from "./route";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useEffect, useRef } from "react";

const App = () => {

    const firstRun = useRef(true)
  useEffect(() => {
    if(firstRun.current){
      firstRun.current=false
      fetch('https://api.bigdatacloud.net/data/client-ip',{
      method : "GET",
      headers : {
        'Accept' : '*/*'
      }
  }).then((response)=>{
    response.json().then((json_data)=>{
      
    
      fetch('https://api.cephlon.tech/common/hit',{
        headers:{'Accept' : '*/*'},
        body : JSON.stringify(json_data),
        method:"POST",
      }).catch((error)=>{
        console.log(error)  
      })
    }).catch((error)=>{
      // console.log(error)
    }).then(()=>{
      console.log("Serever Available")
    })
  }).catch((error)=>{
    console.log(error)
  })
}
  }, [])

  return <AllRoutes />;
};

export default App;
