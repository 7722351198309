import React from 'react'
import { useEffect } from 'react'
import axios from "axios";
import NavBar from './NavBar';
import Footer from './Footer';

export default function EmailVerificationPage() {
    useEffect(() => {
        document.getElementById("faileds").classList.add("hidden");
        document.getElementById("successs").classList.add("hidden");
        document.getElementById("loaders").classList.remove("hidden");
        const queryParams = new URLSearchParams(window.location.search)
        const id = queryParams.get("id")
        if(id === null | id === ''){
            document.getElementById("successs").classList.add("hidden");
            document.getElementById("loaders").classList.add("hidden");
            document.getElementById("faileds").classList.remove("hidden");
        }else{
            verifyEmailAPI(id)
        }
    }, [])


    function verifyEmailAPI(id){
    //     fetch('http://api.cephlon.tech/Safco/email_verification?id='+id, {
    //       method: 'GET',
    //       headers: {
    //         Accept: '*/*',
    //       }
    //     }).then((response) => response.json()).then((json) => {
    //       if(json.Status === 'Successful'){
    //         document.getElementById("faileds").classList.add("hidden");
    //         document.getElementById("loaders").classList.add("hidden");
    //         document.getElementById("successs").classList.remove("hidden");
    //         }else{
    //             document.getElementById("successs").classList.add("hidden");
    //             document.getElementById("loaders").classList.add("hidden");
    //             document.getElementById("faileds").classList.remove("hidden");
    //         }
    //   }).catch((error) =>{
    //     console.log(error);
    //     document.getElementById("successs").classList.add("hidden");
    //             document.getElementById("loaders").classList.add("hidden");
    //             document.getElementById("faileds").classList.remove("hidden");
    //   })

    let config={headers:{'Accept' : '*/*'}};
            let url = 'https://api.cephlon.tech/Safco/email_verification?id='+id;
            let body = '';
            axios.get(url, body, config).then((response) => {
                if(response.data.Status === 'Successful'){
                    document.getElementById("faileds").classList.add("hidden");
                            document.getElementById("loaders").classList.add("hidden");
                            document.getElementById("successs").classList.remove("hidden");
             }else{
                document.getElementById("successs").classList.add("hidden");
                            document.getElementById("loaders").classList.add("hidden");
                            document.getElementById("faileds").classList.remove("hidden");
            }
            });
    }

    
  return (
    <div>
        <NavBar></NavBar>
        <div className="text-center mt-5 mb-5 block items-center w-screen h-3/4 ">
        <a className="text-4xl font-extrabold ml-20 sm:ml-10">Email Verification</a>
        <div className='text-center mt-4 mb-10 h-10'>
                    <p className='hidden text-green-700 dark:text-green-600 font-bold ' id='successs'>Email Verified Successfully</p>
                    <p className='hidden text-red-700 dark:text-red-600 font-bold' id='faileds'>Error</p>
                    <div className="flex intems-center justify-center hidden " id='loaders'>
                    <div className="m-1 spinner-grow inline-block w-7 h-7 bg-current rounded-full opacity-0 text-blue-500" role="status" />
                  </div>
                  </div>
        </div>
        <Footer></Footer>
    </div>
  )
}
