import styled from "styled-components";

export const AboutUsStyled = styled.div``;
export const CardStyled = styled.div`
padding: 90px 0px;
margin-top: 100px;
background-color: #fafafa;
  position: relative;
  .slick-list,
  .slick-track {
    height: 100%;
  }
  .MainOurTeam{
    text-align: center;
    text-transform: uppercase;
    margin-bottom:62px;
  }
  .layer-img {
    position: absolute;
    right: 0;
    bottom: 0px;
    z-index: -1;
  }

  .card-wrapper {
    max-width: 391px;
    /* padding: 65px 31.3px 38px 29px; */
    background-color: #cfebd1;
    box-shadow: 34.85px 29.63px 48.34px -3px rgba(51, 102, 255, 0.05);
    display: flex;
    flex-direction: column;
    margin: 0 16px;
  }
  .ourTeamContaint{
    max-width: 391px;
    width: 100%;
    margin: auto;

  }
  .ourTeamTitle{
    margin-top: 24px;
    font-size: 20px;
    font-weight: 600;
  }
  .ourTeamDes{
    color: #6A778B;
    font-size:16px;
    font-weight: 400;
  }
  .image-wrapper {
    /* width: 121.9px;
    height: 121.9px; */
    flex-grow: 0;
    /* background-color: #43a047; */
    margin: 0 auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .title {
    margin-top: 43px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
  .description {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.65;
    color: #000;
    line-height: 1.81;
    letter-spacing: -0.2px;
    text-align: center;
  }
  .btn-wrapper {
    display: flex;
    align-items: end;
    height: 100%;
    margin-top: 30px;
  }
  .learn-more-btn {
    font-size: 16px;
    font-weight: 400;

    line-height: 2;
    letter-spacing: -0.6px;

    color: #000;
    display: flex;
    align-items: center;
    gap: 31px;
    border: none;
    outline: none;
    background: transparent;
    margin: auto auto 0 auto;
  }

  .card-container {
    width: 100%;
    display: flex;
    gap: 30px;
    padding: 30px 0;
  }

  .card-container {
    height: 580px !important;
    overflow: hidden;
  }
  @media (max-width: 768px) {
    .layer-img {
      display: none;
    }
  }
`;
export const AboutCompanyStyled = styled.div`
  .about-company {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
  }

  .about-company-cardcontent {
    gap: 35px;
    max-width: 477px;
    width: 100%;
    padding-bottom: 22px;
  }

  .about-company-card {
    max-width: 624px;

    width: 100%;
    padding: 64px 31px 64px 64px;
    position: absolute;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background-color: #fff;
    left: 7%;
    top: -11%;
  }

  .about-text-lable {
    width: 100%;
    font-size: 13px;

    margin: 0px;
    font-weight: 400;
    letter-spacing: 1.63px;
  }

  .about-content {
    color: #4c4c4c;
    margin: 0px;
    line-height: 28px;
    font-weight: 400;
  }

  .lable-wrapper {
    width: 100%;
  }
  .about-title {
    /* max-width: 496px; */
    width: 100%;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -2px;
    color: #232536;
    line-height: 64px;
  }

  .company-img {
    width: 100%;
  }

  .our-mision {
    max-width: 516px;
    width: 100%;
  }
  .mision-lable {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1.63px;

  }

  .mission-title {
    font-size: 28px;
    margin: 0%;
    padding-bottom: 16px;
    padding-top: 28px;
    color: #232536;
    font-weight: 700;
    line-height: 40px;
  }

  .mision-content {
    font-size: 16px;
    font-weight: 400;
    color: #6d6e76;
    line-height: 28px;
  }

  .our-vision {
    max-width: 516px;
    width: 100%;
  }
  .vision-lable {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1.63px;
  }

  .vision-title {
    font-size: 28px;
    margin: 0%;
    padding-bottom: 16px;
    padding-top: 28px;
    color: #232536;
    font-weight: 700;
    line-height: 40px;
  }

  .vision-content {
    font-size: 16px;
    font-weight: 400;
    color: #6d6e76;
    line-height: 28px;
  }

  .company-mision-vision {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: auto;
    gap: 28px;
    background-color: #fafafa;
  }

  @media (max-width: 1250px) {
    .about-company {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      padding: 0px 0px;
      
    }

    .company-img {
      padding: 0px;
    }

    .aout-company-cardcontent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      margin: 0px;
    }

    .about-text-lable {
      padding-left: 0px;
      margin: 0px;
    }
    .about-title {
      margin: 0px;
      font-size: 20px;
      padding-bottom: 20px;
      line-height: unset;
      letter-spacing: -1px;
    }

    .about-company-card {
      position: static;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
      padding: 0;
    }

    .about-common-title {
      font-size: 16px;
      line-height: unset;
    }
  }
`;

export const ChephlonFoundersStyled = styled.div`
  .company-founders {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
  }

  .founders-information {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .founders-lable {
    font-size: 13px;
    font-weight: 400;
  }

  .founders-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.43;
    color: #232536;
    padding-top: 28px;
    padding-bottom: 14px;
    margin: 0%;
    text-align: center;
  }

  .founders-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #6d6e76;
    max-width: 637px;
    width: 100%;
    margin: 0%;
    padding-bottom: 50px;
  }

  .founder-post {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding-bottom: 100px;
  }

  .founder-img {
    max-width: 391px;
    max-height: 424px;
    width: 100%;
    height: 100%;
    background-color: #cfebd1;
  }

  .founder-name {
    font-size: 20px;
    font-weight: 600;
    color: #061c3d;
    margin-top: 24px;
  }

  .founder-work {
    font-size: 16px;
    font-weight: 400;
    color: #6a778b;
    margin-top: 8px;
  }
  @media (max-width: 1250px) {
    .about-common-title {
      font-size: 16px;
    }
  }
`;

export const OurStoryStyled = styled.div`
  .our-story {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 70px 0px;
    background-color: #fafafa;
  }

  .our-story-information {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .our-story-lable {
    font-size: 13px;
    line-height: normal;
    letter-spacing: 1.63px;
  }

  .our-story-content {
    max-width: 600px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
    color: #6d6e76;
    text-align: center;
    margin: 0px;
  }

  .company-story-image {
    max-width: 1110px;
    width: 100%;
    padding: 50px 10px;
  }

  .our-story-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: baseline;
  }

  .our-story-title {
    max-width: 451px;
    width: 100%;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    color: #232536;
  }

  .our-story-title-content {
    max-width: 469px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #6d6e76;
    margin: 0%;
  }

  @media (max-width: 1250px) {
    .about-common-title {
      font-size: 16px;
    }
  }
`;
