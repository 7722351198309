import callIcon from "../assets/call-calling.png";
import mailIcon from "../assets/sms.png";
import locationIcon from "../assets/location.png";
import {
  aboutUrl,
  contactUrl,
  partnerUrl,
  productUrl,
  serviceUrl,
} from "./route";

export const footerDescription = (
  <>
    Manufacturer and Designer of smart Automation & Electronic Products. We
    <br /> write stack for MCU and as well as host, database server and API
    server in
    <br /> order to collect and store data. We also provide our clients with
    business
    <br /> and process optimization tools.
  </>
);

export const footerContactUsText = "Contact Us";
export const contactsMenu = [
  {
    name: "Tel",
    logoUrl: callIcon,
    value: "+91 93409 82323",
  },
  {
    name: "Mail",
    logoUrl: mailIcon,
    value: "support@cephlon.tech",
  },
  {
    name: "Registered Office",
    logoUrl: locationIcon,
    value: (
      <>
        1052, New Cloth Market, Kesar
        <br /> Bagh Road, Indore (MP) -<br /> 452009, INDIA
      </>
    ),
  },
  {
    name: "Manufacturing Unit",
    logoUrl: locationIcon,
    value: (
      <>
        1st Floor, 256, New Cloth Market,
        <br /> Kesar Bagh Road, Indore (MP) -<br /> 452009, INDIA
        {/* GSTIN 23AARFC5621L1Z9 */}
      </>
    ),
    number: "GSTIN 23AARFC5621L1Z9",
  },
  // {
  //   name: "",
  //   logoUrl:  Gst ,
  //   value: "GSTIN 23AARFC5621L1Z9",
  // },
];

export const shortsLinks = [
  {
    name: "Our Story",
    link: `${aboutUrl}?id=story`,
  },
  {
    name: "Our Services",
    link: serviceUrl,
  },
  {
    name: "Our Products",
    link: productUrl,
  },
  {
    name: "Contact Us",
    link: contactUrl,
  },
  {
    name: "Partner With Us",
    link: partnerUrl,
  },
  // {
  //   name: "FAQs",
  //   link: "#",
  // },
  // {
  //   name: "Site Map",
  //   link: "#",
  // },
];

export const otherLinks = [
  {
    name: "Our Clients",
    link: "#",
  },
  {
    name: "Clients Review",
    link: "/?id=clients",
  },
  {
    name: "Ongoing Customer",
    link: "#",
  },
  {
    name: "Customer Support",
    link: "#",
  },
  {
    name: "Ticketing",
    link: "#",
  },
  {
    name: "Call Center",
    link: "#",
  },
];

export const footerCopyrightsText = `© Cephlon Technology, All Right Reserved.`;
