import mailIcon from "../assets/fillmail.png";
import callIcon from "../assets/fillcall.png";
import locationIcon from "../assets/filllocation.png";
export const contactText = "Contact Us";
export const description = "Already Completed With Project Ideation? Connect With Us Now For Real-time Visualization And Implementation.";
export const contactDetail = [
  {
    title: "EMAIL US",
    value: "support@cephlon.tech",
    iconUrl: mailIcon,
  },
  {
    title: "PHONE NUMBER",
    value: "+91 98270 75240",
    iconUrl: callIcon,
  },
  {
    title: "ADDRESS",
    value: (
      <>
        1052, New Cloth Market,Kesar Bagh
        <br />
        Road,Indore (MP) - 452009,INDIA
      </>
    ),
    iconUrl: locationIcon,
  },
];
