import styled from "styled-components";
export const TabinationStyled = styled.div`
  width: 100%;
  max-width: 665px;
  margin: 0 auto;
  box-shadow: 34.9px 29.6px 48.3px 0 rgba(51, 102, 255, 0.05);
  border-radius: 16px;
  background-color: #fff;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 500;
  color: #121;
  padding: 28px 0px 37px 0px;

  .tab-wrapper {
    border-right: 1px dashed #d7e2ee;

    padding: 0px 40px 0px 16px;
  }

  .divider {
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  .tab-wrapper:last-child {
    border: none;
  }
  a {
    color: black;
    position: relative;
    transition: all 0.3s ease-in-out;
  }
  a:hover {
    text-decoration: none;
  }
  a::before {
    width: 0%;
    content: "";
    position: absolute;
    left: 0;
    bottom: -12px;
    height: 2px;
    background: var(--gradient-color);
    transition: all 0.3s ease-in-out;
  }
  a::after {
    position: absolute;
    content: "";
    left: 0%;
    bottom: -12px;
    height: 0px;
    width: 0px;
    background: var(--purple-color);
    border-radius: 50%;
    transform: translateY(2px);
    transition: all 0.3s ease-in-out;
  }
  .active {
    letter-spacing: unset;
  }
  .active::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -12px;
    height: 2px;
    width: 100%;
    background: var(--gradient-color);
    transition: all 0.3s ease-in-out;
  }
  .active::after {
    position: absolute;
    content: "";
    left: 100%;
    bottom: -12px;
    height: 6px;
    width: 6px;
    background: var(--purple-color);
    border-radius: 50%;
    transform: translateY(2px);
    transition: all 0.3s ease-in-out;
  }

  /* @media (min-width: 426px) {
    .divider {
      font-size: 16px;
    }
  } */
  @media (max-width: 426px) {
    padding: 16px 0px 26px 0px;

    .tab-wrapper {
      padding: 0 16px;
    }
    .divider {
      font-size: 10px;
    }

    .divider {
      font-size: 10px;
    }
  }
`;

export const FirstTabination = styled.div`
  .tabCard {
    max-width: 385px;
    width: 100%;
    box-shadow: 34.85px 29.63px 48.34px -3px rgba(51, 102, 255, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 58px 31px 38px 29px;
    /* justify-content: space-between; */
    gap: 15px;
    height: 100%;
    border-radius: 8px;
    background-color: #fff;
    justify-content: space-between;
  }
  .tab {
    background: var(--gradient-color);
    padding: 1px;
    border-radius: 8px;
  }
  .tabCard .mainCardImg {
    max-width: 136px;
    width: 100%;
    height: 136px;
    object-fit: contain;
  }
  .cardTitle {
    /* margin: 39px 0px 15px 0px; */
    font-size: 24px;
  }
  .cardDescription {
    text-align: center;
    margin-bottom: 43px;
    font-weight: 400;
    line-height: 29px;
  }
  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 34px;
    row-gap: 100px;
  }
  .buttonArrow {
    background-image: url(rightArrow);
    width: 50px;
    height: 50px;
  }
  .arrowButton {
    margin-left: 30px;
  }
  .popup-main {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0px;
    z-index: 100;
  }
  .popup-container {
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35px 0;
    border-radius: 25px;
    height: 90%;
    overflow: auto;
    max-width: 1140px;
    width: 100%;
  }
  .popup-close {
    display: flex;
    justify-content: end;
  }
  .popup-title {
    display: flex;
    justify-content: center;
    margin-top: -30px;
  }
  .popup-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  .popup-cards {
    .tabCard {
      max-width: 300px;
      width: 100%;
      padding: 28px 21px;
    }
    .mainCardImg{
      width: 70px;
      height: 70px;
    }
  }

  @media (min-width: 320px) {
    .card-wrapper {
      justify-content: center;
    }
  }

  @media (min-width: 1270px) {
    .card-wrapper {
      justify-content: unset;
    }
  }
`;
