import React from 'react'
import {Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'
import jwt from 'jwt-decode'
import LogoDark from '../img/logo-dark.png';
import LogoLight from '../img/logo-light.png';
import './OldUI.css'

export default function NavBar(){  

    return (
      <div>
        <Link to='/Console' id='console-link'></Link>
        <div className="header w-full">
          <div className="container-fluid ">
            <div className="row ">
              <div className="w-full ">
                
                <div id="navv" className="navbar navbar-expand-lg navbar-dark flex px-5 lg:px-0">
                  <div className=" basis-full lg:basis-1/6 flex flex-row gap-1 justify-between">
                    <Link to="/" className=" w-[250px] ">
                      <div className='-ml-5'>
                      <img src={LogoDark} alt="Logo" className="brand" id='logo-img' />
                      </div>
                    </Link>
                    <button type="button" className="navbar-toggler border " data-toggle="collapse"
                      data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" id="nav_button">
                      <span className="navbar-toggler-icon "></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
        </div>
    )
};