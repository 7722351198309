import React from "react";
import { FooterStyled } from "./styled";
import MailIcon from "../../assets/footerMail.webp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  contactsMenu,
  footerContactUsText,
  footerCopyrightsText,
  footerDescription,
  shortsLinks,
} from "../../content/footer";
import logo from "../../assets/logo.svg";
import makeInIndia from "../../assets/makeInIndia.png";
import { contactUrl, homeUrl } from "../../content/route";
import Gst from "../../assets/GST.png";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <FooterStyled>
      <footer className="website-container">
        <div className="first-section section">
          <h3 className="title">
            <img src={logo} alt="logo" onClick={() => navigate(homeUrl)} />
          </h3>
          <h5 className="description">{footerDescription}</h5>
          <div className="footer-input-wrapper">
            <input className="footer-input" placeholder="Enter your Email" />
            <button className="footer-btn btn-hover">Submit</button>
            <img className="mail-icon" src={MailIcon} alt="mailicon" />
          </div>

          <iframe
            className="footer-map"
            title="Contact Map"
            style={{
              display: location.pathname === contactUrl ? "block" : "none",
            }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d947.259212930511!2d75.84383796101433!3d22.684695800862542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fc5afd3a49e7%3A0xf3812168e30c50ba!2sNew%20Cloth%20Market%2C%20Palakhedi%2C%20Indore%2C%20Madhya%20Pradesh%20452009!5e0!3m2!1sen!2sin!4v1655213802146!5m2!1sen!2sin"
          />
        </div>
        <div className="second-section section">
          <h3 className="title">{footerContactUsText}</h3>
          {contactsMenu.map((menu, index) => (
            <ContactUsMenu
              key={index}
              logoUrl={menu.logoUrl}
              name={menu.name}
              value={menu.value}
              number={menu.number}
            />
          ))}
        </div>
        <div className="third-section section">
          <h3 className="title">Short Links</h3>
          {shortsLinks.map((link, index) => (
            <Link to={link.link} className="links" key={index}>
              {link.name}
            </Link>
          ))}
          <div
            style={{
              height: "100%",
              display: location.pathname === contactUrl ? "block" : "none",
            }}
          >
            <img
            alt="make in india"
              src={makeInIndia}
              style={{ width: "140px", height: "100%", objectFit: "contain" }}
            />
          </div>
        </div>
        {/* <div className="fourth-section section">
          <h3 className="title">Others</h3>
          {otherLinks.map((link, index) => (
            <Link to={link.link} className="links" key={index}>
              {link.name}
            </Link>
          ))}
        </div> */}
      </footer>
      <div className="last-section">{footerCopyrightsText}</div>
    </FooterStyled>
  );
};

export default Footer;

const ContactUsMenu = ({
  logoUrl = "",
  name = "",
  value = "",
  number = "",
}) => {
  // console.log(value, "[][[]");
  const location = useLocation();
  return (
    <>
      <div className="contact-menu-wrapper">
        <div className="logo">
          <img src={logoUrl} alt="logoUrl" />
        </div>
        <div style={{ margin: "auto 0" }}>
          <div className="name">{name}</div>
          <div className="value">{value}</div>
        </div>
      </div>
      <div
        style={{
          display: location.pathname === contactUrl ? "block" : "none",
        }}
      >
        <div style={{ display: number ? "block" : "none", marginTop: "-20px" }}>
          <div
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              color: "#9a9ea6",
              fontWeight: "400",
            }}
          >
            <img src={Gst} alt="GST"/>
            {number}
          </div>
        </div>
      </div>
    </>
  );
};
