import OximeterImg from "../assets/Oximeter.png";
import rightArrow from "../assets/rightArrow.png";
import ForeheadThermometer from "../assets/ForeheadThermometer.png";
import weightmachine from "../assets/weightmachine.png";
import Thermometer from "../assets/Thermometer.png";
import EngineMonitoringSystem from "../assets/EngineMonitoringSystem.png";
import GeneratorMonitoringSystem from "../assets/GeneratorMonitoringSystem.png";
import Hex from "../assets/Hex.png";
import Pen from "../assets/Pen.png";
import Fan from "../assets/Fan.png";
import Ira from "../assets/Ira.png";
import Crt from "../assets/Crt.png";

import wIFIRepeater from "../assets/wIFIRepeater.png";
import shockProofDevices from "../assets/shockProofDevices.png";
import unlimitedNodes from "../assets/unlimitedNodes.png";
import meshTechnology from "../assets/meshTechnology.png";
// import privateServers from "../assets/privateServers.png";
import ecosystemforOem from "../assets/ecosystemforOem.png";
import offlineAccess from "../assets/offlineAccess.png";
import voiceControlled from "../assets/voiceControlled.png";
import scenesandScheduling from "../assets/scenesandScheduling.png";
import SmartLock from "../assets/SmartLock.png";
import VideoDoorPhone from "../assets/Videodoorphone.png";
import MotionSensor from "../assets/MotionSensor.png";
import Leddimmer from "../assets/leddimmer.png";

import privateServers from "../assets/privateServers.png";

import productheader from "../assets/productheader.png";

export const productheaderImageUrl = productheader;
export const productHeaderTitle = "Products";
export const productHeaderSubTitle = (
  <>
    Designing and manufacturing state-of-the-art Automation, Electronics
    <br /> and digital products for a future-proof and technologically advanced
    <br /> world.
  </>
);

// Contect Form
export const FromMainTitle = "Connect Now!";
export const FromDescription = (
  <>
    Troubled with our services or product details? If yes, connect us now!
    Explore our prompt and
    <br /> user-friendly support for your business.
  </>
);
export const FromPhone = "+91 93409 82323";
export const FromEmail = "support@cephlon.tech";
export const ConnectSocialTitle = ""; // change "Connect with us"
export const ContectBtn = "Contact Us";

export const tabnation = ["Automation", "Engineering product", "Healthcare"];
export const tabs = {
  Healthcare: [
    {
      icons: OximeterImg,
      title: "Oximeter",
      discription:
        "Our team calibrates the oximeters with maximum precision in the display readings, including the SP02 concentration and the BPM metrics.",
      button: "Learn more",
      buttonIcon: rightArrow,
    },
    {
      icons: ForeheadThermometer,
      title: "Forehead Thermometer",
      discription:
        "We design the intricate PCBs for the forehead thermometers in a way that the temperature displayed on the screen can be as close to the original reading as possible. ",
      button: "Learn more",
      buttonIcon: rightArrow,
    },
    {
      icons: weightmachine,
      title: "Weight Machine",
      discription:
        "From calibrating the manual weight machines to designing and manufacturing the electronic circuits for the digital ones, we are well-versed with different projects.",
      button: "Learn more",
      buttonIcon: rightArrow,
    },
    {
      icons: Thermometer,
      title: "Digital Thermometer",
      discription:
        "Our digital thermometers are the epitome of perfection, having state-of-the-art program for showing the accurate readings at different conditions.",
      button: "Learn more",
      buttonIcon: rightArrow,
    },
  ],
  "Engineering product": [
    {
      icons: EngineMonitoringSystem,
      title: "Engine Monitoring System",
      discription:
        "From manufacturing different sensors for engine monitoring system to designing and developing the software, we help our clients to track real-time performance and efficiency of their engines.",
      button: "Learn more",
      buttonIcon: rightArrow,
    },
    {
      icons: GeneratorMonitoringSystem,
      title: "Generator Monitoring System",
      discription:
        "Our experts are concerned with designing and implementing the monitoring system for different types of generators. We cater to our client demands by developing a customizable and scalable software.",
      button: "Learn more",
      buttonIcon: rightArrow,
    },
  ],
  Automation: [
    {
      icons: Hex,
      title: "HEX",
      discription:
        "Experience control at your fingertips with HEX! This sleek 6-toggle switch gives you power over lights, appliances, and more with its 6 outputs & switches. Elevate your convenience with voice & app control, making managing your space a breeze.",
      button: "Learn more",
      buttonIcon: rightArrow,
    },
    {
      icons: Pen,
      title: "PEN",
      discription:
        "PEN is your seamless and intuitive 5-toggle switch & 1 regulator combo! Easily manage your lights and power settings. Plus, enjoy the luxury of voice and app control for added convenience.",
      button: "Learn more",
      buttonIcon: rightArrow,
    },
    {
      icons: Fan,
      title: "FAN",
      discription:
        "It's your gateway to seamless connectivity! Control both your fan & its speed effortlessly. Transform your room's mood ease and simplicity.",
      button: "Learn more",
      buttonIcon: rightArrow,
    },
    {
      icons: Ira,
      title: "IRA",
      discription:
        "Meet IR-the ultimate in remote control technology! With voice and app control, this sleek IR controller takes charge of your TV, AC &entertainment system. Say goodbye to remote chaos with this sophisticated solution.",
      button: "Learn more",
      buttonIcon: rightArrow,
    },
    {
      icons: Crt,
      title: "CRT",
      discription:
        " CR is your ticket to curtain revolution! Wavegoodbye to manual efforts-control your curtains with flair using just your voice or a simple tap on the app. It's curtain automation made stylishly simple.",
      button: "Learn more",
      buttonIcon: rightArrow,
    },

    {
      icons: Leddimmer,
      title: "Led Dimmer",
      discription: (
        <p
          style={{
            fontSize: "30px",
            color: "red",
            padding: "66px 55px 80px 55px",
          }}
        >
          Coming Soon..
        </p>
      ),
      button: "Learn more",
      buttonIcon: rightArrow,
    },
    {
      icons: SmartLock,
      title: "Smart Lock",
      discription: (
        <p
          style={{
            fontSize: "30px",
            color: "red",
            padding: "66px 55px 80px 55px",
          }}
        >
          Coming Soon..
        </p>
      ),
      button: "Learn more",
      buttonIcon: rightArrow,
      size: "200px",
    },
    {
      icons: VideoDoorPhone,
      title: "Video door phone",
      discription: (
        <p
          style={{
            fontSize: "30px",
            color: "red",
            padding: "66px 55px 80px 55px",
          }}
        >
          Coming Soon..
        </p>
      ),
      button: "Learn more",
      buttonIcon: rightArrow,
    },
    {
      icons: MotionSensor,
      title: "Motion sensor",
      discription: (
        <p
          style={{
            fontSize: "30px",
            color: "red",
            padding: "66px 55px 80px 55px",
          }}
        >
          Coming Soon..
        </p>
      ),
      button: "Learn more",
      buttonIcon: rightArrow,
    },
  ],
};
export const Feature = [
  {
    icons: meshTechnology,
    title: "Mesh Technology",
    discription:
      "Our self healing mesh technology ensures that all your devices are connected to the network 24x7.",
    button: "Learn more",
    buttonIcon: rightArrow,
  },
  {
    icons: wIFIRepeater,
    title: "WIFI Repeater",
    discription:
      "All our devices have a built-in Wi-Fi Repeater, to provide you with high speed internet throughout your home.",
    button: "Learn more",
    buttonIcon: rightArrow,
  },
  {
    icons: shockProofDevices,
    title: "Shock Proof Devices",
    discription:
      "These devices convert regular switches to shock proof switches. This enhances safety of your home over the decades.",
    button: "Learn more",
    buttonIcon: rightArrow,
  },
  {
    icons: offlineAccess,
    title: "Offline Access",
    discription:
      "Our devices can be operated from anywhere in the world through internet, But you can also control our devices locally without any internet connection.",
    button: "Learn more",
    buttonIcon: rightArrow,
  },
  {
    icons: privateServers,
    title: "Private Servers",
    discription:
      "We have a network of privately owned servers through out the nation, to enhance safety of your home and to ensures low latency during device operations",
    button: "Learn more",
    buttonIcon: rightArrow,
  },
  {
    icons: voiceControlled,
    title: "Voice Controlled",
    discription:
      "All our devices can be operated via Google Home as well as Amazon-Alexa.",
    button: "Learn more",
    buttonIcon: rightArrow,
  },
  {
    icons: scenesandScheduling,
    title: "Scenes & Scheduling",
    discription:
      "Set a schedule every single time to serve you according to your daily routine & preferences. Schedule time and countdown timer.",
    button: "Learn more",
    buttonIcon: rightArrow,
  },
  {
    icons: unlimitedNodes,
    title: "Unlimited Nodes",
    discription:
      "You can connect unlimited number of devices on a single network.",
    button: "Learn more",
    buttonIcon: rightArrow,
  },

  {
    icons: ecosystemforOem,
    title: "Ecosystem for Oem",
    discription:
      "Our smart Soc converts any home appliance to a smart home device No hassel of technical development and server maintainance.",
    button: "Learn more",
    buttonIcon: rightArrow,
  },
];
