import styled from "styled-components";

export const FooterStyled = styled.div`
  margin-top: 100px;
  background-color: #fafafa;
  .footer-map {
    height: 210px;
    width: 100%;
    max-width: 457px;
    border: none;
    border-radius: 5px;
  }
  .title {
    cursor: pointer;
  }
  footer {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 40px 16px;
  }
  .section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .first-section {
    display: block;
    .description {
      font-size: 14px;
      line-height: 1.35;
      color: #9a9ea6;
      font-weight: 400;
      margin-bottom: 16px;
    }
    .title {
      margin-bottom: 16px;
    }
    .footer-input-wrapper {
      height: 48px;
      background: var(--gradient-color);
      padding: 1px;
      max-width: 457px;
      width: 100%;
      border-radius: 10px;
      position: relative;
      margin-bottom: 16px;

      .footer-input {
        border: none;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        color: #9a9ea6;
        padding-left: 48px;
        font-weight: 400;
      }
      .footer-input::placeholder {
        color: #9a9ea6;
        font-weight: 400;
      }
      .mail-icon {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%);
      }
      .footer-input:focus,
      .footer-btn:focus {
        outline: none;
      }
      .footer-btn {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        margin: auto;
        border: none;
        height: 38px;
        color: white;
        padding: 0 27.5px;
        border-radius: 5px;
        background: var(--gradient-color);
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }
  .second-section {
    gap: 20px;
  }
  .third-section,
  .fourth-section {
    gap: 20px;
  }

  .contact-menu-wrapper {
    display: flex;
    gap: 12px;
    .logo {
      width: 36px;
      height: 36px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      font-size: 12px;
      color: #000;
    }
    .value {
      font-size: 16px;
      color: #9a9ea6;
      font-weight: 400;
    }
  }
  .contact-menu-wrapper:last-child {
    align-items: center;
  }
  .title {
    font-weight: 600;
    font-size: 18px;
  }
  .links {
    font-size: 14px;
    color: #9a9ea6;
    font-weight: 400;
  }
  .links:hover {
    text-decoration: none;
  }
  .last-section {
    height: 49px;
    text-align: center;
    color: #9a9ea6;
    font-size: 14px;
    border-top: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
