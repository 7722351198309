import React from "react";
import { ImageStyled, WhychooseStyled } from "./styled";
import { subLableWhy, whyChooseData, whyChooseTitle } from "../../content/home";

export default function Whychoose() {
     return (
          <WhychooseStyled>
               <div className="website-container">
                    <div>
                         <p className="why-heading text-gradient">{whyChooseTitle}</p>
                         <h1 className="why-sublable">{subLableWhy}</h1>
                    </div>

                    <div className="why-data">
                         {whyChooseData.map((item, index) => (
                              <ImageStyled className="main-box" key={index} $isLastChild={whyChooseData.length - 1 === index}>
                                   <div className="box-img">
                                        <img src={item.imgUrl} alt="" />
                                   </div>
                                   <div className="why-content">
                                        <h2 className="why-content">{item.content}</h2>
                                        <p className="why-pre">{item.descritpion}</p>
                                   </div>
                              </ImageStyled>
                         ))}
                    </div>
               </div>
          </WhychooseStyled>
     );
}
