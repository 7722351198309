import React, { useEffect, useState } from "react";
import {
  AboutCompanyStyled,
  AboutUsStyled,
  CardStyled,
  ChephlonFoundersStyled,
  OurStoryStyled,
} from "./styled";
import {
  OurStoryContent,
  OurStoryContentBesideTitle,
  OurStoryLable,
  OurStoryTitle,
  OurTeam,
  cardContent,
  cardLable,
  companyImageUrl,
  companyStoryImage,
  contentBesideCard,
  foundersContent,
  foundersLable,
  foundersPost,
  foundersTitle,
  misionContent,
  misionLable,
  misionTitle,
  visionContent,
  visionLable,
  visionTitle,
} from "../../content/aboutUs";
import HigherOrderComp from "../HigherOrderComp";
import ContactForm from "../ContactForm";

import Slider from "react-slick";
const settings = {
  initialSlide: 0,
  centerMode: false,
  variableWidth: true,
  infinite: true,
  slidesToShow: 1,
  speed: 800,
  cssEase: "linear",
  autoplay: true,
  slidesToScroll: 1,
  swipeToSlide: true,
  pauseOnHover: false,
};

const AboutUs = () => {
  const [team, setTeam] = useState([]);
  useEffect(() => {
    // Ensure the first slide is always the first item
    setTeam(OurTeam);
  }, []);
  return (
    <AboutUsStyled>
      <div className="website-container">
        <AboutCompanyStyled>
          <div className="about-company">
            <div className="about-company-cardcontent">
              <card className="about-company-card">
                <div className="lable-wrapper">
                  <span class="about-text-lable text-gradient ">
                    {cardLable}
                  </span>
                </div>
                <p className="about-title">{cardContent}</p>
              </card>
              <p className="about-content">{contentBesideCard}</p>
            </div>

            <img alt="Company" className="company-img" src={companyImageUrl} />

            <div className="website-container company-mision-vision">
              <div className="our-mision">
                <span className="mision-lable text-gradient">
                  {misionLable}
                </span>
                <h1 className="mission-title about-common-title">
                  {misionTitle}
                </h1>
                <p className="mision-content">{misionContent}</p>
              </div>

              <div className="our-vision">
                <span className="vision-lable text-gradient">
                  {visionLable}
                </span>
                <h1 className="vision-title about-common-title">
                  {visionTitle}
                </h1>
                <p className="vision-content">{visionContent}</p>
              </div>
            </div>
          </div>
        </AboutCompanyStyled>

        <ChephlonFoundersStyled>
          <div className=" company-founders">
            <div className="founders-information">
              <span className="founders-lable text-gradient">
                {foundersLable}
              </span>
              <h1 className="founders-title about-common-title">
                {foundersTitle}
              </h1>
              <p className="founders-content">{foundersContent}</p>
            </div>

            <div className="founder-post">
              {foundersPost.map((item, index) => {
                return (
                  <>
                    <div className="founder-information" key={index}>
                      <img
                        src={item.imgUrl}
                        alt={`${item.name}`}
                        className="founder-img"
                      />
                      <p className="founder-name">{item.name}</p>
                      <p className="founder-work">{item.post}</p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </ChephlonFoundersStyled>

        <OurStoryStyled id="story">
          <div className="our-story">
            <div className="our-story-information">
              <span className="our-story-lable text-gradient">
                {OurStoryLable}
              </span>
              <p className="our-story-content">{OurStoryContent}</p>
            </div>

            <img
              src={companyStoryImage}
              alt="Company story"
              className="company-story-image"
            />

            <div className="our-story-wrapper website-container">
              <h1 className="our-story-title about-common-title">
                {OurStoryTitle}
              </h1>
              <p className="our-story-title-content">
                {OurStoryContentBesideTitle}
              </p>
            </div>
          </div>
        </OurStoryStyled>
      </div>

      <CardStyled>
        <p className="MainOurTeam text-gradient">our team</p>
        <Slider className="card-container" {...settings}>
          {team.map((item, index) => (
            <div key={index}>
              <div className="card-wrapper">
                <div className="image-wrapper">
                  <img
                    src={item.imgUrl}
                    alt={item.name}
                    style={{ maxWidth: "391px", width: "100%" }}
                  />
                </div>
              </div>
              <div className="ourTeamContaint">
                <h3 className="ourTeamTitle">{item.name}</h3>
                <p className="ourTeamDes">{item.post}</p>
              </div>
            </div>
          ))}
        </Slider>
      </CardStyled>

      <ContactForm />
    </AboutUsStyled>
  );
};

export default HigherOrderComp(AboutUs);
