import styled from "styled-components";

export const CardStyled = styled.div`
  position: relative;
  .slick-list,
  .slick-track {
    height: 100%;
  }
  
  .layer-img {
    position: absolute;
    right: 0;
    bottom: 0px;
    z-index: -1;
  }

  .card-wrapper {
    max-width: 391px;
    padding: 65px 31.3px 38px 29px;
    background-color: #fff;
    box-shadow: 34.85px 29.63px 48.34px -3px rgba(51, 102, 255, 0.05);
    display: flex;
    flex-direction: column;
    margin: 0 60px;
  }
  .image-wrapper {
    width: 121.9px;
    height: 121.9px;
    flex-grow: 0;
    /* background-color: #43a047; */
    margin: 0 auto;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .title {
    margin-top: 43px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
  .description {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.65;
    color: #000;
    line-height: 1.81;
    letter-spacing: -0.2px;
    text-align: center;
  }
  .btn-wrapper {
    display: flex;
    align-items: end;
    height: 100%;
    margin-top: 30px;
  }
  .learn-more-btn {
    font-size: 16px;
    font-weight: 400;

    line-height: 2;
    letter-spacing: -0.6px;

    color: #000;
    display: flex;
    align-items: center;
    gap: 31px;
    border: none;
    outline: none;
    background: transparent;
    margin: auto auto 0 auto;
  }

  .card-container {
    width: 100%;
    display: flex;
    gap: 30px;
    padding: 30px 0;
  }

  .card-container {
    height: 580px !important;
    overflow: hidden;
  }
  @media (max-width: 768px) {
    .layer-img {
      display: none;
    }
  }
`;
