import styled from "styled-components";

export const HomeStyled = styled.div`
  margin-top: 100px;
  .home-pre {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    margin: auto;
    letter-spacing: 1.63px;
  }
  .about-content {
    display: flex;
    margin-top: 55px;
  }
  .heading {
    font-size: 36px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -1.2px;
    text-align: center;
    color: #161c2d;
    margin-top: 24px;
    margin-bottom: 0;
  }
  .img-content {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  .content {
    opacity: 0.65;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: -0.2px;
    text-align: left;
    color: #000;
    margin-bottom: 0;
  }
  .content:nth-child(2) {
    margin-top: 14px;
  }

  .img-div {
    flex-grow: 1;
    max-width: 544px;
    width: 100%;
  }
  .img-div img {
    width: 100%;
  }
  .home-content {
    flex-grow: 1;
    /* max-width: 453px; */
    width: 100%;
  }
  .home-btn {
    padding: 14px 29px;
    border-radius: 5px;
    background-image: var(--gradient-color);
    color: white;
    border: none;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin-top: 25px;
  }
  @media screen and (max-width: 320px) {
    .heading {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: -1.2px;
      text-align: center;
      color: #161c2d;
    }
    .img-content {
      flex-wrap: wrap;
      justify-content: space-around;
    }
    .home-pre {
      text-align: center;
      font-size: 14px;
    }
    .content {
      font-size: 14px;
    }
    .home-btn {
      padding: 8px 15px;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 768px) {
    .img-content {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const OurMetricStyled = styled.div`
  margin-top: 100px;
  .our-metrics {
    display: flex;
    flex-direction: column;
    background-image: var(--gradient-color);
    max-width: 100%;
    padding: 100px 0px;
  }
  .metricHeading {
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.76px;
    text-align: center;
    color: #fff;
    padding-bottom: 20px;
    margin: 0;
  }
  .subtitle {
    align-self: stretch;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding-bottom: 50px;
    margin: 0;
  }
  .box {
    flex-grow: 1;
    padding: 48px 16px;
    box-shadow: 34.9px 29.6px 48.3px 0 rgba(51, 102, 255, 0.05);
    border-bottom: solid 4px var(--orange-color);
    background-color: #fff;
    border-radius: 10px 10px 0px 0px;

    h2{
      font-size: 38px;
      font-weight: bold;
      color: #2d2d2d;
      letter-spacing: -0.76px;
      line-height: 1.3;
      margin: 0;
    }
    p{
      margin: 5px 0 0 0;
      color: #2d2d2d;
      line-height: 1.3;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .box {
    text-align: center;
  }
  .metrics-box {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 320px) {
    .metrics-box {
      flex-wrap: wrap;
    }
    .metricHeading {
      font-size: 19px;
      font-weight: 500;
      padding-bottom: 0px;
    }
    .subtitle {
      font-size: 11px;
      padding-bottom: 14px;
    }
    .our-metrics {
      padding: 38px 55px;
    }
  }
`;

export const ServiceStyled = styled.div`
  .home-title {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.63px;
    text-align: center;
    margin: 0;
  }
  .services-heading {
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -1.2px;
    text-align: center;
    color: #161c2d;
    padding-bottom: 50px;
    margin: 0;
  }
  .home-title {
    padding-top: 100px;
    padding-bottom: 23px;
  }
  @media screen and (max-width: 320px) {
    .home-title {
      padding-top: 50px;
      padding-bottom: 13px;
    }
    .services-heading {
      font-size: 16px;
      padding-bottom: 6px;
    }
  }
`;
