import React from "react";
import Layout from "../Layout";

const HigherOrderComp = (Comp) => {
  
  return (props) => {
  
    return (
      <Layout {...props}>
        <Comp {...props} />
      </Layout>
    );
  };
};

export default HigherOrderComp;
