import styled from "styled-components";
import Line from "../../assets/Line.png";

export const ImageStyled = styled.div`
     max-width: 288px;
     width: 100%;
     .box-img {
          padding: 21px 22px 20px;
          /* opacity: 0.1; */
          border-radius: 15px;
          /* padding-bottom: 33px; */
          position: relative;
     }
     .box-img::after {
          content: "";
          display: ${(props) => (props.$isLastChild ? "none" : "block")};
          position: absolute;
          background-image: url("${Line}");
          top: 50%;
          left: 100px;
          width: 100%;
          height: 4px;
          background-repeat: no-repeat;
          z-index: -3;
          transform: translateY(-50%);
     }
     @media (max-width: 1270px) {
          .box-img::after {
               display: none;
          }
     }
`;

export const WhychooseStyled = styled.div`
     .why-heading {
          padding-top: 100px;
          padding-bottom: 24px;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.63px;
          text-align: center;
          margin: 0;
          text-transform: uppercase;
     }
     .why-sublable {
          font-size: 36px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: -1.2px;
          text-align: center;
          color: #161c2d;
          padding-bottom: 50px;
          margin: 0;
     }
     .why-content {
          width: 277px;
     }
     .why-content {
          font-size: 22px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.55;
          letter-spacing: -0.5px;
          text-align: left;
          color: #000;
          padding-bottom: 13px;
     }
     .why-pre {
          /* width: 288px; */
          opacity: 0.65;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.81;
          letter-spacing: -0.2px;
          text-align: left;
          color: #000;
     }
     .why-data {
          display: flex;
          gap: 29px;
          flex-wrap: wrap;
          justify-content: center;
     }
     @media screen and (max-width: 320px) {
          .why-data {
               display: flex;
               justify-content: center;
               flex-wrap: wrap;
          }
          .why-heading {
               padding-top: 58px;
               padding-bottom: 5px;
          }
          .why-sublable {
               font-size: 16px !important;
               padding-bottom: 5px !important;
          }
     }

     @media screen and (max-width: 768px) {
          .why-data {
               flex-wrap: wrap;
               justify-content: center;
               align-items: center;
               gap: 30px !important;
          }
     }

     @media screen and (max-width: 992px) {
          .why-data {
               justify-content: center;
               flex-wrap: wrap;
               gap: 32px;
          }
          .why-sublable {
               font-size: 32px;
               padding-bottom: 30px;
          }
          .jFzEeU .why-heading {
               padding-top: 82px;
               padding-bottom: 8px;
          }
     }
`;
