import HomePageHeaderImage from "../assets/homePageHeader.png";

import profileImg from "../assets/profile-img.png";
import layerImg from "../assets/file-img.png";
import messageImg from "../assets/message-img.png";
import deadlineImg from "../assets/svgviewer-png-output.png";

import image from "../assets/layer-img.png";
import star from "../assets/Vector.png";
import arrow from "../assets/tail-right.png";

import airbnb from "../assets/airbnb.png";
import google from "../assets/google.png";
import amezon from "../assets/amazon.png";
import microsoft from "../assets/microsoft.png";
import fedex from "../assets/fedex.png";

import cetus from "../assets/Cetus.png";
import pcbDesign from "../assets/PCBDesign.png";
import mcuProgramming from "../assets/MCUProgramming.png";
import pcbAssembly from "../assets/PCBAssembly.png";
import threeDProductModelling from "../assets/3DProductModelling.png";
import safco from "../assets/Safco.png";
import projectM from "../assets/Project-M.png";
import digit from "../assets/Digit.png";


import Testi1 from "../assets/testi1.png"
import Testi2 from "../assets/testi2.png"
import Testi3 from "../assets/testi3.png"
import Testi4 from "../assets/testi4.png"
import Testi5 from "../assets/testi5.png"
import Testi6 from "../assets/testi6.png"
import Testi7 from "../assets/testi7.png"
import Testi8 from "../assets/testi8.png"

export const headerImageUrl = HomePageHeaderImage;
export const headerTitle = (
  <>
    India's Leading
    <span className="text-gradient"> Automation</span> And
    <br />
    <span className="text-gradient"> Electronics </span>
    Manufacturer
  </>
);
export const headerSubTitle = (
  <>
   Designing and manufacturing state-of-the-art Automation, Electronics<br/> and Digital products for a future-proof and technologically advanced<br/> world.
  </>
);

export const homeTitle = "ABOUT US";

export const heading = (
  <>
    We are in the league owing to our
    <br /> dedicated contributions
  </>
);

export const homeContent =
  "With the goal of improving the domestic and international market and reducing dependencies on the foreign import, We at Cephlon Technology LLP offer state-of-the-art innovative Automation & Electronics products solutions for home,office,industries and government projects to our clients . From designing, developing and manufacturing multi layered PCB boards to integrating with multifarious SoC layers, Our Services include various Paradigms of the market. We have a well-built team, Comprising of dedicated and passionate members. They not only have in-depth understanding of Automation and Electronic operations, but also have the ability to steer through any bottleneck with perseverance and knowledge.";

export const homeContent1 =
  "Apart from designing, developing and manufacturing of Automation and Electronic circuits and products, Our Company is also concerned with software and hosting services. We have invested in a next-gen server, Cetus, known for its extremely low latency and faster network speed. It can cater to the modern-day business operations requiring higher signal strength and undisturbed communication channel. The prime goal of our company is to provide adaptable, expandable, and scalable business solutions to our clients for enhancing the operational efficiency and reducing disruptions.";

export const oueHeading = "See what the numbers have to say";
export const subtitle =
  "A direct insight into how the tables turned in our favor in this hypercompetitive market landscape! ";

export const homeTitle1 = "OUR SERVICES";
export const headingservices = (
  <>
    We Focus On Customer-Centric
    <br /> Services To Cater To Their
  </>
);

export const subLable = (
  <>We create complex MCU stack to suffice our clients needs</>
);

export const whyChooseTitle = "Why choose us";
export const subLableWhy = (
  <>
    Our commitment is what
    <br /> reassures our clients for a
  </>
);

export const reviewHeading = "Client Testimonials ";
export const reviewContent =
  "Learn what our customers have to say about Cephlon!";
export const urlImage = image;
export const whyChooseData = [
  {
    imgUrl: profileImg,
    content: "Customer-Centric",
    descritpion:
      "At Cephlon, we cherish a customer-centric approach for curating our Automation and Electronics products and other forms of business solutions to cater to our client needs head-on.",
  },
  {
    imgUrl: layerImg,
    content: "Organized workflows",
    descritpion:
      "Our company follows a well-defined workflow with a pre-established hierarchy, from including all the stakeholders in decision-making to maintaining transparency in information.",
  },
  {
    imgUrl: messageImg,
    content: "Feedback loops",
    descritpion:
      "Our clients can leave their valuable feedbacks about our services and solutions, thereby giving us an opportunity to improve ourselves further and provide them optimal experience in future.",
  },
  {
    imgUrl: deadlineImg,
    content: "Unwavering deliveries ",
    descritpion:
      "Regardless of how stringent the timelines are, we make sure to deliver the project right on time without compromising the quality at any point.",
  },
];

export const servicesData = [
  {
    imgUrl: cetus,
    content: "Cetus",
    descritpion:
      "Offering a 99.9% server uptime and hosting both dynamic and static websites from different global locations.",
    button: "Learn more",
    arrow: arrow,
  },
  {
    imgUrl: pcbDesign,
    content: "PCB Design",
    descritpion:
      "From single to multiple layers, we design intricate structures of different PCBs according to the project requirements.",
    button: "Learn more",
    arrow: arrow,
  },
  {
    imgUrl: mcuProgramming,
    content: "MCU Programming",
    descritpion: "We create complex MCU stack to suffice our clients needs",
    button: "Learn more",
    arrow: arrow,
  },
  {
    imgUrl: pcbAssembly,
    content: "PCB Assembly",
    descritpion: "We design production ready multilayer PCB for our clients",
    button: "Learn more",
    arrow: arrow,
  },
  {
    imgUrl: threeDProductModelling,
    content: "3D Product Modelling",
    descritpion:
      "We create 3D products design, optimised for mould fabrication",
    button: "Learn more",
    arrow: arrow,
  },
  {
    imgUrl: safco,
    content: "Safco",
    descritpion:
      "Implementing OAuth 2.0, Safco is a highly protected and secured server based on user authorization protocols.",
    button: "Learn more",
    arrow: arrow,
  },
  {
    imgUrl: projectM,
    content: "Project-M",
    descritpion:
      "Hosted on state-of-the-art Cetus server, this is a Git-based project code management tool for SDLCs.",
    button: "Learn more",
    arrow: arrow,
  },
  {
    imgUrl: digit,
    content: "Digit",
    descritpion:
      "Advanced and secured inventory and account management tool leveraging the latest blockchain and ledger technology.",
    button: "Learn more",
    arrow: arrow,
  },
];

export const ourMetricData = [
  {
    price: "95+",
    descritpion: "PCB Designing ",
  },
  {
    price: "50+",
    descritpion: "Projects Completed ",
  },
  {
    price: "27+",
    descritpion: "New Partnerships ",
  },
  {
    price: "90+",
    descritpion: "Software Designed",
  },
];

export const imgArray = [airbnb, google, amezon, microsoft, fedex];

export const reviewLable = "Clients Testimonials";
export const reviewTitle =
  "Learn what our customers have to say about Cephlon!";
export const clientsReview = [
  {
    imgUrl: Testi1,
    name: "Siddharth Joshi",
    email: "UI Designer @Boo",
    rateImg: star,
    description: (
      <>
         Cephlon technology has been instrumental in our business for the past 9 months, assisting in the creation and implementation of new software. Their team is reliable, smart, and friendly, and we highly recommend their services. 
      </>
    ),
  },
  {
    imgUrl: Testi2,
    name: "Rohan Malhotra",
    email: "Lead Designer @Creative",
    rateImg: star,
    description: (
      <>
      The Cephlon team has effectively aligned user experience with strategic goals, delivering stellar results in design and production, and consistently contributing their expertise to the final product, making them a recommended choice for challenging design and business analysis projects. 
      </>
    ),
  },
  {
    imgUrl: Testi3,
    name: "Riya Kapoor",
    email: "Lead Designer @Creative",
    rateImg: star,
    description: (
      <>
        Having worked with many different home automation companies over the years, I found it refreshing to find one that truly understands what they are doing. Cephlon technology listens to what the customer wants and then delivers on that. In this day and age of profit-first companies, it's nice to find one who puts the needs and happiness of their customers first.
      </>
    ),
  },
  {
    imgUrl: Testi4,
    name: "Aarav Patel",
    email: "Lead Designer @Creative",
    rateImg: star,
    description: (
      <>
        I partnered up with Cephlon for hosting my website on their dedicated server. I was quite surprised to see the minimal signal latency and the high efficiency of the network. 
      </>
    ),
  },


  {
    imgUrl: Testi5,
    name: "Vihaan Gupta",
    email: "Lead Designer @Creative",
    rateImg: star,
    description: (
      <>
        Cephlon helped me with my Electronics project by designing a multi-layered PCB and provided me with an accurate visualization of the product through 3D modeling. 
      </>
    ),
  },
  {
    imgUrl: Testi6,
    name: "Meera Rao",
    email: "Lead Designer @Creative",
    rateImg: star,
    description: (
      <>
        Two years ago, we partnered with cephlon for a complex automation project, requiring industry-specific methodology and algorithms. They provided excellent post-development support.
      </>
    ),
  },
  {
    imgUrl: Testi7,
    name: "Karan Desai",
    email: "Lead Designer @Creative",
    rateImg: star,
    description: (
      <>
        The team at cephlon provided excellent solutions using advanced techniques, delivering excellent work on time and a pleasant experience. 
      </>
    ),
  },
  {
    imgUrl: Testi8,
    name: "Ananya Sharma",
    email: "Lead Designer @Creative",
    rateImg: star,
    description: (
      <>
        I purchased a bunch of digital and electronic thermometers from Cephlon. They always show accurate and precise temperature readings. What surprised me is the customer service they offer for any problem faced post the purchase. 
      </>
    ),
  },
];
