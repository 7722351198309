import React, { useEffect, useRef, useState } from "react";
import { NavbarStyled } from "./styled";
import logo from "../../assets/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Hamburger from "../../assets/hamburger.png";
import { aboutUrl, contactUrl, homeUrl, loginUrl, productUrl, serviceUrl } from "../../content/route";

const menu = [
     {
          name: "Home",
          path: homeUrl,
     },
     {
          name: "Products",
          path: productUrl,
     },
     {
          name: "About",
          path: aboutUrl,
     },
     {
          name: "Contact",
          path: contactUrl,
     },
     {
          name: "Services",
          path: serviceUrl,
     },
     // {
     //   name: "Services",
     //   path: `${homeUrl}?id=services`,
     // },
];
const Navbar = () => {
     const { pathname } = useLocation();
     const hamburgerRef = useRef();
     const [open, setOpen] = useState(false);
     const navigate = useNavigate();

     useEffect(() => {
          const handelOutSIdeClick = (e) => {
               if (!hamburgerRef?.current?.contains(e.target)) {
                    setOpen(false);
               }
          };
          document.addEventListener("click", handelOutSIdeClick);
     }, []);

     return (
          <NavbarStyled>
               <div className="main">
                    <div className="logo-wrapper">
                         <img src={logo} alt="logo" onClick={() => navigate(homeUrl)} />
                    </div>
                    <div className="menu-wrapper">
                         {menu.map((item) => (
                              <LinkComp item={item} pathname={pathname} key={item.path} {...item} />
                         ))}
                    </div>
                    <button className="btn  btn-hover" onClick={() => navigate(loginUrl)}>
                         LOGIN
                    </button>
                    <div className="mobile-menu-wrapper">
                         <img
                              src={Hamburger}
                              ref={hamburgerRef}
                              alt="hamburgerIcon"
                              onClick={() => setOpen(!open)}
                              onBlur={() => {
                                   console.log("called");
                              }}
                         />
                         {open && (
                              <div className="mobile-menu">
                                   {menu.map((item) => (
                                        <LinkComp item={item} pathname={pathname} key={item.path} {...item} />
                                   ))}
                                   <button className="btn btn-hover" onClick={() => navigate(loginUrl)}>
                                        LOGIN
                                   </button>
                              </div>
                         )}
                    </div>
               </div>
          </NavbarStyled>
     );
};

export default Navbar;

const LinkComp = ({ item = {}, pathname = "", ...props }) => {
     const [isHover, setIsHover] = useState(false);
     let newPathname = pathname.endsWith("/") && pathname.length > 1 ? pathname.slice(0, -1) : pathname;
     const isHomePage = item.name === "Home";
     let className = "";
     if (isHomePage) {
          className = `${["/", homeUrl].includes(newPathname) ? "active text-gradient" : ""}`;
     } else if (item.path === newPathname || isHover) {
          className = `active text-gradient`;
     }
     return (
          <Link
               className={`link ${className}`}
               to={item.path}
               onMouseEnter={() => setIsHover(true)}
               onMouseLeave={() => setIsHover(false)}
               {...props}
          >
               {item.name}
          </Link>
     );
};
