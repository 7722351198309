import styled from "styled-components";

export const LoginStyled = styled.div`
  & * {
    user-select: none;
  }
  .main-wrapper {
    display: flex;
    height: 100vh;
    justify-content: center;

    img {
      height: 100%;
      width: 50%;
    }

    .form-wrapper {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;

      .form {
        max-width: 390px;
        width: 100%;

        .title {
          text-align: center;

          font-family: "SF UI Text";
          font-size: 24px;
          font-weight: 600;
          line-height: 1.33;
          letter-spacing: -0.24px;
          text-align: center;
          color: #181c32;
          margin-bottom: 24px;
        }

        .subTitle {
          font-size: 14px;
          text-align: center;
          color: #a1a5b7;
          margin-bottom: 28px;
        }

        .social-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          margin-bottom: 28px;
        }
        .social-button {
          width: 61px;
          height: 61px;

          display: flex;

          justify-content: center;
          align-items: center;

          padding: 19px;
          border: solid 1px #e0e0e9;
          background-color: #fbfafa;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .line {
          margin-top: 28px;
          margin-bottom: 28px;
          border-top: 1px solid #eff2f5;
          position: relative;

          .line-text {
            font-size: 12px;
            color: #a1a5b7;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 8px;
            background: white;
          }
        }

        .form-input-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .form-input {
          width: 100%;
          padding: 10px 16px;
          border-radius: 6px;
          border: solid 1px #d9dbe4;
          background-color: #fff;
          outline: none;
          color: #a1a5b7;
          font-size: 14px;
        }

        .permission-wrapper {
          display: flex;
          gap: 9px;
          align-items: center;

          .checkbox {
            width: 20px;
            height: 20px;
            border: 1px solid #d9dbe4;
            border-radius: 6px;
            cursor: pointer;
            padding: 6px 5px;
            display: flex;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .permission-text {
            font-size: 13px;
            color: #5e6278;

            span {
              color: #f58e67;
            }
          }
        }

        .btn {
          width: 100%;
          padding: 10px 20px;
          border-radius: 6px;
          background: var(--gradient-color);
          font-size: 14px;
          color: white;
          outline: none;
          border: none;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .main-wrapper {
      > img {
        display: none;
      }
      .form-wrapper {
        width: 100%;
      }
    }
  }
`;
