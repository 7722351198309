import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer/index";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Layout = ({ children, ...props }) => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (searchParams.get("id")) {
      setTimeout(() => {
        document
          ?.querySelector(`#${searchParams.get("id")}`)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 0);
      // navigate(pathname, { replace: true });
    } else {
      window.scrollTo(0, 0);
    }
  }, [navigate, pathname, searchParams]);
  return (
    <>
      <Header {...props} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
