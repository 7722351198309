import styled from "styled-components";

export const HeaderStyled = styled.div`
     height: ${(props) => (props.$isHomePage == "true" ? "836px" : "442px")};
     width: 100%;
     overflow: hidden;
     position: relative;

     .header-img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          z-index: -2;
          position: relative;
     }
     .title {
          text-align: center;
          font-size: 74px;
          color: white;
          font-weight: 700;

          span {
               font-weight: 700;
          }
     }
     .subtitle {
          font-size: 24px;
          color: #dedede;
          text-align: center;
          opacity: 0.7;
          font-weight: 400;
          margin: 0;
     }
     .home-btn {
          background-image: var(--gradient-color);
          color: white;
          margin-top: 50px;

          padding: 18px 25px 18px 26px;
          border-radius: 5px;
          border: none;
          outline: none;
          font-weight: 600;
     }
     .header-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          z-index: 0;
     }
     @media (max-width: 768px) {
          .title {
               font-size: 40px;
          }
          .subtitle {
               font-size: 14px;
          }
     }
`;
