import React from "react";
import { useState, useEffect, useSearchParams } from "react";
import axios from "axios";
import './OldUI.css'
import NavBar from "./NavBar";
import Footer from "./Footer";


export default function ResetPwdFromEmail(){
    const [pwd, setPwd] = useState(null);
    const [rePwd, setRePwd] = useState(null);
    const [apiCall, setApiCall] = useState(null);
    

    var serviceName = 'Cephlon';

    const getPwd = (data) => {
        setPwd(data.target.value);
    }
    const getRePwd = (data) => {
        setRePwd(data.target.value);
    }
    
    useEffect(() => {
        if(apiCall !== null){
            if(apiCall){
                document.getElementById("failed1").classList.add("hidden");
                document.getElementById("failed2").classList.add("hidden");
                document.getElementById("loader1").classList.add("hidden");
                document.getElementById("success1").classList.remove("hidden");
            }else{
                document.getElementById("failed1").classList.add("hidden");
                document.getElementById("success1").classList.add("hidden");
                document.getElementById("loader1").classList.add("hidden");
                document.getElementById("failed2").classList.remove("hidden");
            }
        }

    },[apiCall])
    useEffect(() => {
        
    })

    function sendChangePwd(){
        document.getElementById("failed1").classList.add("hidden");
        document.getElementById("failed2").classList.add("hidden");
        document.getElementById("success1").classList.add("hidden");
        document.getElementById("loader1").classList.remove("hidden");

        if(pwd === rePwd && pwd !== null){
            // var otp = '565';
            const queryParams = new URLSearchParams(window.location.search)
            const otp = queryParams.get("otp")
            const newServiceName = queryParams.get("service")
            console.log('otp is : ', otp);
            let config={headers:{'Accept' : '*/*'}};
            let url = 'https://api.cephlon.tech/Safco/user-password?service='+newServiceName+'&&command=forgototp&&otp='+otp+'&&NewPwd='+pwd;
            let body = '';
            axios.post(url, body, config).then((response) => {
                if(response.data.Status === 'Successful'){
                 setApiCall(true);
             }else{
                setApiCall(false);
            }
            });     
        }else{
            document.getElementById("loader1").classList.add("hidden");
            document.getElementById("failed2").classList.add("hidden");
            document.getElementById("success1").classList.add("hidden");
            document.getElementById("failed1").classList.remove("hidden");
        }

    }

    return(
        <div>
            <NavBar></NavBar>
        <div className="text-center mt-5 flex flex-col justify-center items-center w-screen h-3/4 ">
            <a className="text-4xl font-extrabold sm:ml-10 mb-10">Enter New Password</a>
            <input type="password" placeholder="New Password" className="my-1 mr-4 ml-1 dark:bg-gray-900 h-8 w-max" onChange={getPwd}></input>
            <input type="password" placeholder="Re-Type Password" className="my-1 mr-4 ml-1 dark:bg-gray-900 h-8 w-max" onChange={getRePwd}></input>
            <button className="btn btn-primary rounded-lg mt-4 mr-4 mb-2" onClick={sendChangePwd}>Change Password</button>

            <div className='text-center mb-10 h-10 mr-4'>
                    <p className='hidden text-green-700 dark:text-green-500 font-bold mt-2' id='success1'>Password Changed Successfully</p>
                    <p className='hidden text-red-700 dark:text-red-500 font-bold mt-2' id='failed1'>Password Mismatch</p>
                    <p className='hidden text-red-700 dark:text-red-500 font-bold mt-2' id='failed2'>URL Expired</p>
                    <div className="flex intems-center justify-center hidden " id='loader1'>
                    <div className="m-1 spinner-grow inline-block w-7 h-7 bg-current rounded-full opacity-0 text-blue-500" role="status" />
                    <div className="m-1 spinner-grow inline-block w-7 h-7 bg-current rounded-full opacity-0 text-blue-500" role="status" />
                    <div className="m-1 spinner-grow inline-block w-7 h-7 bg-current rounded-full opacity-0 text-blue-500" role="status" />
                  </div>
            </div>
        </div>
        <Footer></Footer>
        </div>
    )
}